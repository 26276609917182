import axios from 'axios';

const HOST = process.env.VUE_APP_HOST_VK;
const HOST_NEW = process.env.VUE_APP_HOST_NEW_VK;

export async function getPostulantByDoc(dni, authToken, gcaptcha) {
  const data = {
    dni,
    gcaptcha,
  };
  try {
    const resp = await axios({
      url: `${HOST}/landing/postulants/dni`,
      method: 'POST',
      data,
      headers: {
        authorizationToken: authToken,
      },
    });

    return resp.data;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
}

export async function validateDocumentWithIGC(igc, document) {
  try {
    const resp = await axios({
      url: `${HOST_NEW}/postulations/landing/${igc}/validate/${document}`,
      method: 'GET',
    });
    return resp.data;
  } catch (error) {
    throw error;
  }
}

export async function saveExperienceData(experienceData, authToken, gcaptcha) {
  const data = {
    data: experienceData,
    gcaptcha,
  };
  try {
    const resp = await axios({
      url: `${HOST}/landing/experiences`,
      method: 'POST',
      data,
      headers: {
        authorizationToken: authToken,
      },
    });
    return resp.data;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
}

export async function saveFamilyData(familyData, authToken, gcaptcha) {
  try {
    const data = {
      data: familyData,
      gcaptcha,
    };
    const resp = await axios({
      url: `${HOST}/landing/family-data`,
      method: 'POST',
      data,
      headers: {
        authorizationToken: authToken,
      },
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function saveThecnicalData(thecnicalData, authToken, gcaptcha) {
  try {
    const data = {
      data: thecnicalData,
      gcaptcha,
    };
    const resp = await axios({
      url: `${HOST}/landing/technical-specifications`,
      method: 'POST',
      data,
      headers: {
        authorizationToken: authToken,
      },
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

// 71741046
export async function savePostulant(postulantData, authToken, gcaptcha) {
  try {
    const data = {
      data: postulantData,
      gcaptcha,
    };
    const resp = await axios({
      url: `${HOST}/landing/postulants`,
      method: 'POST',
      data,
      headers: {
        authorizationToken: authToken,
      },
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
}


export async function createPostulant(postulantData) {
  try {
    const resp = await axios({
      url: `${HOST_NEW}/postulations/landing`,
      method: 'POST',
      data: postulantData,
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function saveProfessionalData(professionalData, authToken, gcaptcha) {
  try {
    const data = {
      data: professionalData,
      gcaptcha,
    };
    const resp = await axios({
      url: `${HOST}/landing/professional-data`,
      method: 'POST',
      data,
      headers: {
        authorizationToken: authToken,
      },
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function saveRotationData(rotationData, authToken, gcaptcha) {
  try {
    const data = {
      data: rotationData,
      gcaptcha,
    };
    const resp = await axios({
      url: `${HOST}/landing/rotation-data`,
      method: 'POST',
      data,
      headers: {
        authorizationToken: authToken,
      },
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function saveFeedbackUser(feedbackData, authToken, gcaptcha) {
  const data = {
    data: feedbackData,
    gcaptcha,
  };
  try {
    const resp = await axios({
      url: `${HOST}/landing/feedback`,
      method: 'POST',
      data,
      headers: {
        authorizationToken: authToken,
      },
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

// export async function getAllJobs() {
//   try {
//     const resp = await axios({
//       url: `${HOST}/landing/jobs`,
//       method: 'GET',
//     });
//     return resp.data;
//   } catch (error) {
//     throw new Error(error.message);
//   }
// }

export async function getAllJobs() {
  try {
    const resp = await axios({
      url: `${HOST_NEW}/processes/vacancies/landing/search`,
      method: 'GET',
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getAllJobsByCategory(category, page=1, take=10) {
  try {
    const resp = await axios({
      url: `${HOST_NEW}/processes/vacancies/landing/search?category=${category}&page=${page}&take=${take}`,
      method: 'GET',
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getJobsTypification() {
  try {
    const resp = await axios({
      url: `${HOST_NEW}/processes/vacancies/landing/typification`,
      method: 'GET',
    });
    return resp.data.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getJobById(id) {
  try {
    const resp = await axios({
      url: `${HOST_NEW}/processes/vacancies/landing/${id}`,
      method: 'GET',
    });
    return resp?.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getLandingVersion() {
  try {
    const resp = await axios({
      url: `${HOST}/version-landing`,
      method: 'GET',
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
}
