import Vue from 'vue';
import VueRouter from 'vue-router';
// import getInfoForAgileFlow from '../services/dynamo/agile_flow';

Vue.use(VueRouter);

let isAgileFlow = true;

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../components/NewLanding/Landing/IndexLanding.vue'),
  },
  {
    path: '/sedes',
    name: 'sedes',
    component: () => import('../components/NewLanding/Map/Map.vue'),
  },
  {
    path: '/empleos',
    name: 'empleos',
    component: () => import('../components/NewLanding/EvaluationSteps/LandingEvaluation.vue'),
  },
  {
    path: '/intro',
    name: 'intro',
    props: true,
    component: () => import('../components/Intro.vue'),
  },
  {
    path: '/selection/:igc',
    name: 'selection',
    props: true,
    component: () => import('../components/agile_flow.vue'),
  },
  {
    path: '/selection2',
    name: 'selection2',
    props: true,
    component: () => import('../components/agile_flow.vue'),
  },
  {
    path: '/politicas-de-seguridad',
    name: 'politicas',
    component: () => import('../components/Politicas.vue'),
  },
  {
    path: '/offerDetail/:igc',
    name: 'offerDetail',
    props: true,
    component: () => import('../components/OfferDetail.vue'),
  },
  {
    path: '/reconocimientos',
    name: 'RecognitionPage',
    props: true,
    component: () => import('../components/NewLanding/Recognitions/RecognitionPage.vue'),
  },
  {
    path: '/beneficios',
    name: 'BenefitsPage',
    props: true,
    component: () => import('../components/NewLanding/Benefits/BenefitsPage.vue'),
  },
  {
    path: '/programas',
    name: 'ProgramsPage',
    props: true,
    component: () => import('../components/NewLanding/Programs/ProgramsPage.vue'),
  },
  {
    path: '/empleos/:id',
    name: 'JobDetailPage',
    props: true,
    component: () => import('../components/NewLanding/Jobs/JobDetailPage.vue'),
  },
  {
    path: '/pagina-no-encontrada',
    name: 'NotFoundPage',
    props: true,
    component: () => import('../components/NewLanding/NotFound/NotFoundPage.vue'),
  },
  {
    path: '*',
    redirect: '/pagina-no-encontrada',
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const isAgileFlowResponse = process.env.VUE_APP_IS_AGILE_FLOW;
  if (isAgileFlowResponse === 'true') {
    isAgileFlow = true;
    next();
  }
  next();
});

export default router;
