<template>
  <v-app>
    <v-app-bar v-if="$route.name != 'Convencion'" flat app color="#FFFFFF">
      <v-app-bar-nav-icon @click="drawer = true" class="d-flex d-md-none"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-col md="5" lg="6">
        <v-toolbar-title class="">
          <v-img
            alt="Konecta logo"
            class="mt-1 pointer-path mx-9"
            contain
            :src="require(`./assets/newlanding/Landing/${this.logo.img}`)"
            :max-width="this.logo.size"
            @click="
              () => {
                $router.push('/');
              }
            "
          />
        </v-toolbar-title>
      </v-col>
      <v-spacer></v-spacer>
      <v-col  md="7" lg="6" xl="5" class="d-none d-md-block">
        <v-row>
          <v-col v-for="(item, index) in itemsNavbar" :key="index">
            <Menu
              :title="item.title"
              :items="item.items"
              :isSelect="item.isSelect"
              :path="item.path"
              :tag="item.tag"
              :classString="item.classString"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer v-if="!isMobile"></v-spacer>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary class="pt-12">
      <Expand
        title="Somos Konecta"
        :items="[
          { title: '¿Quiénes somos?', path: '/reconocimientos'},
          { title: 'Conoce nuestras sedes', path: '/sedes' }
        ]"
      >
      </Expand>
      <Expand
        title="Konectados contigo"
        :items="[
          { title: 'Beneficios', path: '/beneficios'},
          { title: 'Nuestros programas', path: '/programas'}
        ]"
      >
      </Expand>
      <Expand title="Konecta empleos" :expanded="false" path="/empleos"></Expand>
              <template v-slot:append>
          <img
                alt="Konecta"
                :src="require('./assets/newlanding/Landing/logo-navbar.svg')"
                width="126"
                class="ml-15 pb-7"
              />
      </template>
    </v-navigation-drawer>
    <v-main>
      <router-view :isMobile="isMobile"></router-view>
    </v-main>
    <v-footer v-if="$route.name == 'home'" padless color="#012754"
    class="px-md-15 py-md-15 white--text py-6 px-4 px-xl-15">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12" class="ml-5 ml-md-0" md="6" lg="5">
            <img
                alt="Konecta test"
                :src="require('./assets/newlanding/Landing/logo-navbar.svg')"
                :width=" isMobile? '156': '300'"
                class=""
              />
              <p class="description-footer py-9">
                Konectamos empresas, Konectamos personas, Konectamos contigo.
              </p>
          </v-col>
          <v-col cols="12" md="6" lg="7">
            <v-row no-gutters>
                <v-col cols="12" md="4" v-for="i in itemsFooter" :key="i.title">
                  <div class="ml-5 mb-12 mt-4">
                    <p class="title-item-footer">{{ i.title }}</p>
                    <div v-for="e in i.items" :key="e.name">
                      <p class="subtitle-item-footer"
                      @click="handleRedirection(e.event, e.about)">{{ e.name }}</p>
                    </div>
                  </div>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mt-md-15">
        <v-row no-gutters>
          <v-col cols="12" class="d-none d-md-block" md="6" lg="5">
                  <p class="mb-0 sentence-footer">
                    © 2023 Konecta Perú. Todos los derechos reservados.</p>
                </v-col>
                <v-col cols="12" md="6" lg="7">
                  <v-row no-gutters class="ml-5">
                    <v-col cols="12" md="4" lg="3" xl="2">
                      <p class="sentence-footer">Nuestras redes</p>
                    </v-col>
                    <v-col cols="12" md="6" lg="7">
                      <div class="">
                          <v-icon color="#98A2B3" class="icon-social-media mr-6"
                          v-for="icon in iconsSocialMedia" @click="handleOpenSocialMedia(icon.link)"
                          :key="icon.icon">
                            {{ icon.icon }}</v-icon>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="d-flex align-center justify-center py-15 d-md-none">
                  <p class="mb-0 sentence-footer">
                    © 2023 Konecta Perú. Todos los derechos reservados.</p>
                </v-col>
        </v-row>

      </v-col>
    </v-row>
    </v-footer>
    <div>
      <UpdateVersion />
    </div>
    <v-fab-transition class="postula"
       v-if="!pathSelect.includes('/empleos') &&
        !pathSelect.includes('/selection')  && !pathSelect.includes('/sedes')">
      <v-btn
        large
        class="postula"
        color="white"
        fixed
        dark
        absolute
        bottom
        right
        fab
        @click="trackBtnApply"
        target="_blank"
      >
        <v-img
          :src="require(`./assets/boton-flotante.png`)"
          alt="POSTULA AQUI"
          class="img-btn"
        >
        </v-img>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
import Expand from './components/NewLanding/NavBar/Expand.vue';
import Menu from './components/NewLanding/NavBar/Menu.vue';
import UpdateVersion from './components/UpdateVersion.vue';

export default {
  name: 'App',
  components: {
    Menu,
    Expand,
    UpdateVersion,
  },
  data() {
    return {
      pathSelect: '',
      drawer: false,
      group: null,
      version: {
        nroVersion: '',
      },
      itemsNavbar: [
        {
          title: 'Somos Konecta',
          items: [
            { title: '¿Quiénes somos?', path: '/reconocimientos', tag: 'Quienessomos' },
            { title: 'Conoce nuestras sedes', path: '/sedes', tag: 'Conocenuestrassedes' },
          ],
          isSelect: true,
          path: '',
        },
        {
          title: 'Konectados contigo',
          path: '',
          items: [
            { title: 'Beneficios', path: '/beneficios', tag: 'Beneficios' },
            { title: 'Nuestros programas', path: '/programas', tag: 'Nuestrosprogramas' },
          ],
          isSelect: true,
        },
        {
          title: 'Konecta empleos',
          path: '/empleos',
          tag: 'Konectaempleos',
          isSelect: false,
        },
      ],
      itemsFooter: [
        {
          title: 'Konecta Perú',
          items: [
            {
              event: '/reconocimientos',
              name: '¿Quiénes somos?',
            },
            {
              event: '/sedes',
              name: 'Nuestras sedes',
            },
            {
              about: '_blank',
              event: 'https://www.konecta-group.com/es/quienes-somos',
              name: 'Nuestros valores',
            },
          ],
        },
        {
          title: 'Konectados contigo',
          items: [
            {
              event: '/beneficios',
              name: 'Beneficios',
            },
            {
              event: '/programas',
              name: 'Nuestros programas',
            },
          ],
        },
        {
          title: 'Destacados',
          items: [
            {
              about: '_blank',
              event: 'https://www.konecta-group.com/es/sustainability',
              name: 'Sostenibilidad',
            },
          ],
        },
      ],
      iconsSocialMedia: [
        {
          link: 'https://twitter.com/Konecta_Peru',
          icon: 'mdi-twitter',
        },
        {
          link: 'https://www.linkedin.com/company/grupo-konecta/',
          icon: 'mdi-linkedin',
        },
        {
          link: 'https://www.facebook.com/KonectaPeruOficial',
          icon: 'mdi-facebook',
        },
        {
          link: 'https://www.instagram.com/konectaperu/',
          icon: 'mdi-instagram',
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      this.pathSelect = to.path;
      if (to.path !== from.path) {
        window.scrollTo(0, 0);
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    logo() {
      const size = this.isMobile ? '126' : '320';
      return {
        img: 'logo-navbar.svg',
        size,
      };
    },
  },
  methods: {
    trackBtnApply() {
      this.$gtag.event('LP_btn_Icono_Postula', {
        event_category: 'LP_btn_Icono_Postula',
        event_label: 'LP_btn_Icono_Postula',
        value: 1,
      });
      this.$router.push('/empleos');
    },
    handleRedirection(url, about) {
      if (about) window.open(url, about);
      else this.$router.push(url);
    },
    handleOpenSocialMedia(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";

.img-btn {
  width: 100px;
  height: 100px;
}
.postula {
  margin: 90px 25px !important;
  &:hover {
    transform: translate(0, -15px);
  }
}
.container-icon-social-media {
  background: none;
  text-transform: none;
}
.icon-social-media {
  width: 24px;
  cursor: pointer;
}
.title-item-footer {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $principal-white;
}

.subtitle-item-footer {
  cursor: pointer;
  font-family: $principal-font !important;

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #eaecf0;
}
.sentence-footer {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 24px;
  color: #ffffff;
}
.description-footer {
  margin: 1% 0%;
  max-width: 320px;
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  color: #eaecf0;
}
.pointer-path {
  cursor: pointer;
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #101828;
}
.btn-work {
  @media (max-width: 600px) {
    font-size: 10px;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
.btn-bolsa {
  width: 180px;
  font-weight: 600;
  font-size: 20px;
  border-radius: 10px;
  border: 2px solid;
  @media (min-width: 600px) {
    width: 220px;
    margin: 0px 5px 0px 20px;
  }
  span {
    font-size: 10px;
    @media (min-width: 600px) {
      font-size: 12px;
    }
  }
}
.mainK {
  margin-top: 50px;
  width: 100vw;
}
.app {
  width: 100vw !important;
}
.v-dialog {
  max-width: 500px !important;
}
@media screen and (max-width: 600px) {
  .v-dialogal {
    max-width: 350px !important;
  }
}

@media(min-width: 600px){
  .title-item-footer {
  color: #98a2b3;
}

.sentence-footer {
  font-size: 14px !important;
}
}
@media(min-width: 900px){
  .img-btn {
  width: 140px;
  height: 140px;
}
.postula {
  margin: 80px 50px !important;
  &:hover {
    transform: translate(0, -15px);
  }
}
}

</style>
