<template>
  <v-row>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="pa-4">
        <h2 class="titulo-card py-2 px-3">
          Hola <span> <v-img src="@/assets/icon-face.svg" max-width="25"></v-img></span>
        </h2>
        <span class="titulo-card text-center py-0 px-2" text-center>
          ¡Tenemos una nueva versión disponible!</span
        >
        <div class="contenido-card py-4 px-5 pa-2">
          Realizaremos la actualización automaticamente, por favor espera unos segundos.
        </div>
        <p class="contenido-card py-0 px-5">
          Si no se realiza la actualización en breve, por favor intenta presionando
          <strong class="sombreado"> Ctrl + F5</strong> o borra tus cookies.
        </p>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { versionActual, versionBug } from '@/main';
import { getLandingVersion } from '../services/dynamo/postulants';

export default {
  name: 'UpdateComponent',
  data() {
    return {
      dialog: false,
    };
  },
  async created() {
    await this.verifyVersion();
  },
  methods: {
    async verifyVersion() {
      const actual = await getLandingVersion();
      const vActual = actual[0]?.ACTUAL?.version;
      const vBugActual = actual[0]?.ACTUAL?.versionBug;

      if (`${vActual}.${vBugActual}` !== `${versionActual}.${versionBug}`) {
        this.dialog = true;
        setTimeout(() => {
          window.location.reload(true);
        }, 5000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.titulo-card {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #00968f;
}

.contenido-card {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.sombreado {
  color: #00968f;
}

img.pequeña {
  width: 1px;
  height: 1px;
}
</style>
