import Vue from 'vue';
import './registerServiceWorker';
import './assets/css/main.css';

import 'material-design-icons-iconfont/dist/material-design-icons.css';
import VueGtag from 'vue-gtag';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;
Vue.use(VueReCaptcha,
  {
    siteKey: process.env.VUE_APP_RECAPTCHA, loaderOptions: { autoHideBadge: true },
  });

const idAnalytics = process.env.VUE_APP_ID_ANALYTICS_PROD;

Vue.use(VueGtag, {
  config: { id: idAnalytics },
});

export const versionActual = '4';
export const versionBug = '1';

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
